import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Warning} from "../../../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../../../components/common/form/input-submit";
import InputSearch from "../../../../../../../components/common/form/input-search";
import Loader from "../../../../../../../components/common/loader/loader";
import {postContainerProducts} from "../../../../../../../api/container";
import {useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import ProductCard from "../../../../../../../components/common/card/product-card";
import {TrashIcon} from "@heroicons/react/outline";
import InputFormattedNumber from "../../../../../../../components/common/form/input-formatted-number";
import {useTranslation} from "react-i18next";
import {ArrowLeftIcon} from "@heroicons/react/solid/esm";
import {getProducts} from "../../../../../../../api/catalog";
import InputDiscountNumber from "../../../../../../../components/common/form/input-discount-number";

export default function FromCatalog({containerDetail, onBack, setIsOpen, setDetail}) {

    const { loggedCompany } = useSelector((state) => state.user);
    const { company } = useSelector((state) => state.userCompany);

    const {handleSubmit, control, setValue} = useForm();
    const { t } = useTranslation();


    let {id, container_id}  = useParams();


    const [selected, setSelected] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [query, setQuery] = useState("");



    const [isCreateLoading, setCreateLoading] = useState(false);
    const [exception, setException] = useState(false);


    const storeOrderProducts = async (data) => {
        if (selected) {
            setCreateLoading(true);
            postContainerProducts({ shipping_id: id, container_id: container_id, data })
                .then((response) => {
                    //console.log(response);
                    setCreateLoading(false);
                    setIsOpen(false);
                    setDetail(response);
                })
                .catch((e) => {
                    setException(e.message);
                    setCreateLoading(false);
                });
        }
    };


    useEffect(() => {
        if(selected){
            setValue('quantity', null);
            setValue('product_id', selected.id);
            setValue('base_volume', selected?.default_dimension?.volume ?? 0);
            setValue('base_gross_weight', selected?.default_dimension?.gross_weight ?? 0);
            setValue('base_net_weight', selected?.default_dimension?.net_weight ?? 0);
            setValue('initial_price', selected?.default_dimension?.initial_price ?? null);
            setValue('base_price', selected?.default_dimension?.base_price ?? null);
            setValue('quantity_box', selected?.default_dimension?.qty_per_packaging ?? 1);

            if(selected?.default_dimension?.length ?? false){
                setValue('length', selected.default_dimension.length);
            }

            if(selected?.default_dimension?.width ?? false){
                setValue('width', selected.default_dimension.width);
            }

            if(selected?.default_dimension?.height ?? false){
                setValue('height', selected.default_dimension.height);
            }

            if(selected?.default_dimension?.radius ?? false){
                setValue('radius', selected.default_dimension.radius);
            }
        }
    }, [setValue, selected]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchProducts() {
            if (loggedCompany) {
                setLoading(true);
                getProducts({
                    company_id: loggedCompany.id,
                    params: { limit: 5, offset: 0, query: query },
                    signal,
                })
                    .then((response) => {
                        setList(response.products);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setList([]);
                        setLoading(false);
                    });
            }
        }

        fetchProducts();
        return () => {
            controller.abort();
        };
    }, [query, loggedCompany]);




    const onSubmit = async (data) => {
        await storeOrderProducts({container_products: [data]});
    };

    return (
        <>
            {
                selected ? (
                    <>

                        <div className="col-span-2 p-2 border flex justify-between ">
                            <ProductCard
                                isSelected={true}
                                isList={false}
                                product={selected}
                                onSelect={(e) => {
                                    e.preventDefault();
                                    // setSelected(null);
                                }}
                            />

                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelected(null);
                                }}
                            >
                                <TrashIcon
                                    className="btn h-10 w-10 bg-red-500 px-3 py-2  text-white hover:bg-red-700"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>

                        <form className="col-span-2 mt-3" onSubmit={handleSubmit(onSubmit)}>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                                <div className={'col-span-3'}>
                                    <Controller
                                        name="initial_price"
                                        rules={{required: false}}
                                        control={control}
                                        render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                            <InputDiscountNumber
                                                label={t("app.container.price")}
                                                name={name}
                                                value={value}
                                                decimalScale={4}
                                                onChangeValues={(e) => {
                                                    setValue('discount', e.discount_string);
                                                    setValue('base_price', e.final_price);
                                                }}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                suffix={" " + (company?.currency?.currency_symbol ?? '')}
                                                errors={error}
                                                isShowFinalPrice={true}
                                                discountList={[]}
                                            />
                                        )}
                                    />
                                </div>


                                <Controller
                                    name="quantity"
                                    rules={{required: true}}
                                    control={control}
                                    render={({
                                                 field: {onChange, value, name},
                                                 fieldState: {error},
                                             }) => (
                                        <InputFormattedNumber
                                            label={t("app.container.quantity")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={""}
                                            suffix={""}
                                            errors={error}
                                        />
                                    )}
                                />

                                <Controller
                                    name="quantity_box"
                                    rules={{required: true}}
                                    control={control}
                                    render={({
                                                 field: {onChange, value, name},
                                                 fieldState: {error},
                                             }) => (
                                        <InputFormattedNumber
                                            label={t("app.container.quantity_box")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={""}
                                            suffix={""}
                                            errors={error}
                                        />
                                    )}
                                />

                                {/*<Controller*/}
                                {/*    name="base_price"*/}
                                {/*    rules={{required: true}}*/}
                                {/*    control={control}*/}
                                {/*    render={({*/}
                                {/*                 field: {onChange, value, name},*/}
                                {/*                 fieldState: {error},*/}
                                {/*             }) => (*/}
                                {/*        <InputFormattedNumber*/}
                                {/*            label={t("app.container.price")}*/}
                                {/*            name={name}*/}
                                {/*            value={value}*/}
                                {/*            onChange={(e) => {*/}
                                {/*                onChange(e);*/}
                                {/*            }}*/}
                                {/*            prefix={""}*/}
                                {/*            suffix={" " + (company.currency?.currency_symbol ?? '')}*/}
                                {/*            errors={error}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <Controller
                                    name="base_volume"
                                    rules={{required: false}}
                                    control={control}
                                    render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                        <InputFormattedNumber
                                            label={t("app.container.volume")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={' m³'}
                                            errors={error}
                                        />
                                    )}
                                />

                                <Controller
                                    name="base_gross_weight"
                                    rules={{required: false}}
                                    control={control}
                                    render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                        <InputFormattedNumber
                                            label={t("app.container.gross_weight")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={' Kg'}
                                            errors={error}
                                        />
                                    )}
                                />

                                <Controller
                                    name="base_net_weight"
                                    rules={{required: true}}
                                    control={control}
                                    render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                        <InputFormattedNumber
                                            label={t("app.container.net_weight")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={' Kg'}
                                            errors={error}
                                        />
                                    )}
                                />

                                <Controller
                                    name="length"
                                    rules={{required: false}}
                                    control={control}
                                    render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                        <InputFormattedNumber
                                            label={t("app.container.length")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={' cm'}
                                            errors={error}
                                        />
                                    )}
                                />

                                <Controller
                                    name="width"
                                    rules={{required: false}}
                                    control={control}
                                    render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                        <InputFormattedNumber
                                            label={t("app.container.width")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={' cm'}
                                            errors={error}
                                        />
                                    )}
                                />

                                <Controller
                                    name="height"
                                    rules={{required: false}}
                                    control={control}
                                    render={({field: {onChange, value, name}, fieldState: {error}}) => (
                                        <InputFormattedNumber
                                            label={t("app.container.height")}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={' cm'}
                                            errors={error}
                                        />
                                    )}
                                />

                            </div>


                            {/* Warning */}
                            {exception && <Warning message={exception}/>}

                            <div className="flex before:flex-1 items-center justify-between mt-6">
                                <InputSubmit
                                    isLoading={isCreateLoading}
                                    label={t("app.container.add_product")}
                                    disabled={!selected?.id}
                                />
                            </div>
                        </form>
                    </>
                ) : (
                    <div className="grid grid-cols-2 gap-6 mb-6">

                        <>

                            <div className="col-span-2">
                                <InputSearch
                                    onSearch={(r) => {
                                        setQuery(r);
                                    }}
                                    autocomplete={false}
                                />
                                <label className="text-xs">{t("app.products.search_product")}</label>
                            </div>

                            <div className="col-span-2 flex flex-col gap-2">
                                <ul className="divide-y divide-gray-200">

                                    {list.map((p) => (
                                        <ProductCard
                                            key={p.id}
                                            isList={true}
                                            isSelected={false}
                                            product={p}
                                            onSelect={(e) => {
                                                e.preventDefault();
                                                setSelected(p);
                                            }}
                                        />
                                    ))}
                                </ul>


                                {isLoading && (
                                    <div className="w-1/2 m-auto px-4 py-3">
                                        <Loader />
                                    </div>
                                )}

                                {!isLoading && (
                                    <div className="col-span-2 flex after:flex-1  items-center justify-between ">
                                        <button
                                            className="text-sm underline inline-flex  items-center"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onBack();
                                            }}
                                        >
                                            <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                                            {t("app.common.back")}
                                        </button>
                                    </div>
                                )}

                            </div>
                        </>
                    </div>
                )
            }
        </>
    );
}
