import {
    COMPANY,
    CUSTOMERS,
    CUSTOMER,
    PRODUCTS,
    ACTIVE_ORDERS,
    ORDER_PRODUCTS,
    SEND_CONNECTION
} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// customer
export const getCustomers = ({company_id, params, signal = null}) =>
    API.get(getPathParam([COMPANY, company_id, CUSTOMERS] ) + (params ? serialize(params)  : ''), signal && {signal: signal});

export const getCustomersDynamic = ({company_id, params, data, signal}) =>
    API.post(getPathParam([COMPANY, company_id, CUSTOMERS] ) + (params ? serialize(params)  : ''), data, {signal: signal});


export const getCustomer = ({company_id, customer_id}) =>
    API.get(getPathParam([COMPANY, company_id, CUSTOMER, customer_id ]));

export const postCustomer = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, CUSTOMER]), data);

export const putCustomer = ({company_id, customer_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, CUSTOMER, customer_id]), data);

export const putCustomerConnection = ({company_id, customer_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, CUSTOMER, customer_id, SEND_CONNECTION]), data);


// Load Configuration
export const getCustomerProducts = ({company_id, customer_id, params}) =>
    API.get(getPathParam([COMPANY, company_id, CUSTOMER, customer_id, PRODUCTS] ) + (params ? serialize(params)  : ''));

export const getCustomerOrderProducts = ({company_id, customer_id, params}) =>
    API.get(getPathParam([COMPANY, company_id, CUSTOMER, customer_id, ORDER_PRODUCTS] ) + (params ? serialize(params)  : ''));

export const getCustomerActiveOrders = ({company_id, customer_id, params}) =>
    API.get(getPathParam([COMPANY, company_id, CUSTOMER, customer_id, ACTIVE_ORDERS] ) + (params ? serialize(params)  : ''));

