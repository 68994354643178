import React, { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Warning } from "../../../../../components/common/alert/banner";
import { getPathParam } from "../../../../../utils/converter";
import { putProductDimension } from "../../../../../api/catalog";
import { useTranslation } from "react-i18next";
import { getPermission, permissionGroup, permissionType } from "../../../../../constants/permissions";
import InputFormattedNumber from "../../../../../components/common/form/input-formatted-number";
import toast from "react-hot-toast";
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import InputSubmit from "../../../../../components/common/form/input-submit";
import { PlusCircleIcon } from "@heroicons/react/outline";
import CircleBtn from "../../../../../components/common/button/circle-btn";
import { TrashIcon } from "@heroicons/react/outline/esm";
import InputToggle from "../../../../../components/common/form/input-toggle";
import InputText from "../../../../../components/common/form/input-text";
import AddDimension from "./add-dimension";
import { PRODUCT, DIMENSION } from "../../../../../api/endpoints";
import RemovePopup from "../../../../../components/common/popup/remove-popup";
import BasePermissionWrapper, { hasPermissionFor } from "../../../../../components/partials/restricted/base-permission-wrapper";
import { useSelector } from "react-redux";
import BaseRestricted from "../../../../../components/partials/restricted/base-restricted";
import Nodata from "../../../../../resources/images/no-data.gif";

const ProductDimension = () => {
  const { productDetail, setProductDetail } = useOutletContext();


  // put product
  const [dimensions, setDimensions] = useState([]);
  const [isAddDimension, setAddDimension] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});


  const { permissions } = useSelector((s) => s.userCompany);

  const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_PACKAGE, permissionType.CREATE);
  const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_PACKAGE, permissionType.EDIT);
  const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_PACKAGE, permissionType.VIEW);
  const canRemove = hasPermissionFor(permissions, permissionGroup.PRODUCT_PACKAGE, permissionType.DELETE);



  useEffect(() => {
    if (productDetail !== null) {
      setDimensions(productDetail?.product_dimensions ?? []);
    }
  }, [productDetail]);

  const { t } = useTranslation();


  return (
    <BasePermissionWrapper requiredPermission={getPermission(permissionGroup.PRODUCT_PACKAGE, permissionType.LIST)} requireExporter={true} redirectWrongCompanyType={true} >


      <section>



        {
          canView ? (
            dimensions.map((dimension, index) => (
              <DimensionDetail
                key={dimension.id}
                defaultOpen={index === 0}
                dimensionDetail={dimension}
                canEdit={canEdit}
                canRemove={canRemove}
                onSetDimensions={(r) => {
                  setDimensions(r);
                  const productData = productDetail;
                  productData['product_dimensions'] = r;
                  setProductDetail(productData);

                }}
                onRemove={(detail) => {
                  setRemoveDetail(detail);
                  setOpenRemove(true);
                }}
              />
            ))
          ) : (
            <BaseRestricted />
          )

        }

        {
          (canView && (dimensions ?? []).length === 0) && (
            <div className="mt-3 mx-auto w-full  bg-white">
              <div className="text-center p-10">
                <img src={Nodata} className="-mt-8 mx-auto" alt="not-found" />
                <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                  {t("app.common.not_found")}
                </h1>
              </div>
            </div>
          )
        }

        {
          canCreate && (
            <div className={'mt-10 mx-auto w-1/2 '}>
              <div
                onClick={() => {
                  setAddDimension(true);
                }}
                className="flex p-2 justify-center items-center   border border-blue-1000 px-4 text-center text-sm font-medium   text-blue-1000 hover:bg-solitude-400/[0.5] cursor-pointer">
                <div className="flex items-center ">
                  <div className={' mr-2  text-blue-1000'}>
                    <PlusCircleIcon
                      className="h-4 w-4"
                      aria-hidden="true"
                    />
                  </div>
                  <span>{t("app.products.add_dimension")}</span>
                </div>
              </div>
            </div>
          )
        }
      </section>




      {
        (isAddDimension && canCreate) && (
          <AddDimension
            isOpen={isAddDimension}
            setIsOpen={setAddDimension}
            onAdded={(r) => {
              const productData = productDetail;
              productData['product_dimensions'] = r;
              setProductDetail(productData);
              setDimensions(r);
            }}
          />
        )
      }


      {(isOpenRemove && canRemove) && (
        <RemovePopup
          isOpen={isOpenRemove}
          setIsOpen={setOpenRemove}
          detail={removeDetail}
          setDetail={(r) => {
            const productData = productDetail;
            productData['product_dimensions'] = r.dimensions;
            setProductDetail(productData);
            setDimensions(r.dimensions);
          }}
        />
      )}

    </BasePermissionWrapper>
  );
};



const DimensionDetail = ({ dimensionDetail, defaultOpen, onSetDimensions, onRemove, canEdit, canRemove }) => {

  const { t } = useTranslation();
  let { id } = useParams();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // put product
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [updateException, setUpdateException] = useState(false);


  const updateProduct = async (data) => {
    setUpdateLoading(true);
    putProductDimension({ product_id: id, dimension_id: data.id, data })
      .then((response) => {
        onSetDimensions(response.dimensions);
        setUpdateLoading(false);
        toast.success(t("app.products.update_success"))
        setUpdateException(false);
      })
      .catch((e) => {
        setUpdateLoading(false);
        setUpdateException(e.message);
      });
  };

  const removeDetail = {
    title: t("app.products.dimension_remove_title"),
    message: t("app.products.dimension_remove_description"),
    endpoint: getPathParam([PRODUCT, id, DIMENSION, dimensionDetail.id])
  };


  useEffect(() => {
    if (dimensionDetail !== null) {
      for (const k in dimensionDetail) {
        setValue(k, dimensionDetail[k]);
      }
    }
  }, [dimensionDetail, setValue]);

  const onSubmit = async (data) => {
    updateProduct(data)
  };

  return (
    <Disclosure defaultOpen={defaultOpen} as={'div'} className={'mt-3 mx-auto w-full shadow bg-white'}>
      {({ open }) => (
        <>
          <div className="flex w-full items-center justify-between bg-solitude-100 px-4 py-4 text-left text-sm font-medium text-blue-1000 hover:bg-solitude-400/[0.8]">
            <div className="flex items-center justify-between">
              {
                canRemove && (
                  <CircleBtn
                    icon={TrashIcon}
                    onClick={() => {
                      onRemove(removeDetail);
                    }}
                    extraClass={"text-red-800 bg-red-100  hover:bg-red-800 hover:text-white"}
                  />
                )
              }

              <span>{dimensionDetail.reference}</span>
            </div>

            <Disclosure.Button>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-blue-1000`}
              />
            </Disclosure.Button>
          </div>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-3 gap-6">

                <InputText
                  id={"reference"}
                  type={"text"}
                  errors={errors.reference}
                  input={{ ...register("reference", { required: true }) }}
                  label={t("app.products.dimension_reference")}
                  isDisabled={!canEdit}
                />

                <Controller
                  name="volume"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputFormattedNumber
                      label={t("app.products.volume")}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      prefix={''}
                      suffix={' m³'}
                      errors={error}
                      isDisabled={!canEdit}
                    />
                  )}
                />
                <Controller
                  name="gross_weight"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputFormattedNumber
                      label={t("app.products.gross_weight")}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      prefix={''}
                      suffix={' Kg'}
                      errors={error}
                      isDisabled={!canEdit}
                    />
                  )}
                />

                <Controller
                  name="net_weight"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputFormattedNumber
                      label={t("app.products.net_weight")}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      prefix={''}
                      suffix={' Kg'}
                      errors={error}
                      isDisabled={!canEdit}
                    />
                  )}
                />

                <Controller
                  name="length"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputFormattedNumber
                      label={t("app.products.length")}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      prefix={''}
                      suffix={' cm'}
                      errors={error}
                      isDisabled={!canEdit}
                    />
                  )}
                />

                <Controller
                  name="width"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputFormattedNumber
                      label={t("app.products.width")}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      prefix={''}
                      suffix={' cm'}
                      errors={error}
                      isDisabled={!canEdit}
                    />
                  )}
                />

                <Controller
                  name="height"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputFormattedNumber
                      label={t("app.products.height")}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      prefix={''}
                      suffix={' cm'}
                      errors={error}
                      isDisabled={!canEdit}
                    />
                  )}
                />

                <Controller
                  name="qty_per_packaging"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputFormattedNumber
                      label={t("app.products.quantity_box")}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      errors={error}
                      isDisabled={!canEdit}
                    />
                  )}
                />

             

              </div>
              <InputText
                  id={'note'}
                  type={"textarea"}
                  errors={errors.description}
                  input={{ ...register('note', { required: false }) }}
                  label={t('app.catalog.note')}
                />

              {/* Warning */}
              {updateException && <Warning message={updateException} />}


              <div className={"mt-5  flex items-center justify-between "}>
                <div className="mr-3">
                  <Controller
                    name={"default_dimension"}
                    rules={{ required: false }}
                    control={control}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <InputToggle
                        label={t("app.products.default_dimension")}
                        description={t("app.products.default_dimension_desc")}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        isDisabled={!canEdit}
                        defaultChecked={value}
                        errors={error}
                      />
                    )}
                  />
                </div>

                {
                  canEdit ? (
                    <InputSubmit isLoading={isUpdateLoading} label={t("app.form.save")} />
                  ) : (
                    <div />
                  )
                }

              </div>
            </form>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

  )
}


export default ProductDimension;
