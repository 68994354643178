import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import BaseList from "../common/base-list";
import { getPathParam } from "../../../utils/converter";
import { COMPANY, CONDITION, CONDITIONS, CONNECTION } from "../../../api/endpoints";

import { PaperClipIcon } from "@heroicons/react/outline";
import ActionsMenu from "../../common/table/actions-menu";
import CardDetail from "../../common/list/card-detail";
import TableDetail from "../../common/list/table-detail";
import { useForm, Controller } from "react-hook-form";
import InputText from "../../common/form/input-text";
import { Warning } from "../../common/alert/banner";
import InputSubmit from "../../common/form/input-submit";
import { fetchCountries } from "../../../reducers/configReducer";
import InputSelect from "../../common/form/input-select";
import InputCheckbox from "../../common/form/input-checkbox";

export default function ConnectionConditions() {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const { connectionId, typeActive, removeActive, onRefresh } = useOutletContext();

    return (
        <>
            <div className="max-w-5xl mx-auto  py-6 px-4 sm:px-6 lg:px-8">

                <div className="flex justify-between items-center flex-wrap gap-4 ">
                    <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                        {t("app.connection.conditions")}
                    </h1>
                </div>



            </div>

            <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8">



                <BaseList
                    columns={[
                        t("app.connection.condition_name_form"),
                        t("app.connection.condition_type_form"),
                        t("app.connection.condition_cost_form"),
                        t("app.connection.condition_price_type_form"),
                        "",
                    ]}
                    endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, CONDITIONS])}
                    storeEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, CONDITION])}
                    updateEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, CONDITION, ':id'])}
                    baseResult={{ total_rows: 0, conditions: [] }}
                    resultLabel={'conditions'}
                    title={t("app.connection.conditions")}
                    showHeader={true}
                    mobRow={MobRow}
                    deskRow={DeskRow}
                    addForm={AddForm}
                    icon={PaperClipIcon}
                    addFormLabel={t("app.catalog.add", { type: t("app.connection.condition") })}
                    btnAddLabel={t("app.catalog.add", { type: t("app.connection.condition") })}
                    editForm={EditForm}
                    editTitle={t("app.connection.condition")}
                    isActiveEdit={true}
                    isActiveFileViewer={true}
                    typeActive={typeActive}
                    removeActive={removeActive}
                    onUpdateParent={() => {
                        onRefresh(true);
                    }}
                />
            </div>
        </>
    );
};

const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { connectionId } = useOutletContext();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, CONDITION, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4 border-b">
            <div className="flex-grow">
                <div className="pb-4 flex justify-between items-center">
                    <div className="font-medium text-lg">{element?.condition_name ?? '-'}</div>
                    <ActionsMenu>
                        <button className={'text-left text-blue-500'} onClick={() => {
                            extras.onEdit(true, element);
                        }}>
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left text-red-500'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}>
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.connection.condition_type")}>
                        {element?.condition_type ?? '-'}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};


const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { connectionId } = useOutletContext();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, CONDITION, element.id])
    };

    return (
        <tr key={element.id} className="border-b">
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    {element?.condition_name ?? '-'}
                </div>
            </TableDetail>

            <TableDetail>
                {element?.condition_type ?? '-'}
            </TableDetail>

            <TableDetail>
                {element?.cost ?? '-'}
            </TableDetail>
            <TableDetail>
                {element?.price_type ?? '-'}
            </TableDetail>

            <TableDetail className="text-right">
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left text-red-500'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}>
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        if (data.price_type.value) {
            data['price_type'] = data.price_type.value;
        }

        if (data.condition_type.value) {
            data['condition_type'] = data.condition_type.value;
        }

        onSubmitData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-1">


                    <InputText
                        id="condition_name"
                        type="text"
                        isRequired={true}
                        errors={errors.condition_name}
                        input={{ ...register("condition_name", { required: true }) }}
                        label={t("app.connection.condition_name")}
                    />


                    <Controller
                        name="condition_type"
                        rules={{ required: true }}
                        control={control}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <InputSelect
                                label={t("app.connection.condition_type")}
                                name={name}
                                options={[
                                    { value: 'bank_cost', label: t("app.connection.bank_cost") },
                                    { value: 'logistic_cost', label: t("app.connection.logistic_cost") },
                                    { value: 'other_cost', label: t("app.connection.other_cost") }
                                ]}
                                value={value}
                                isRequired={true}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                errors={error}
                            />
                        )}
                    />



                    <InputText
                        id="cost"
                        type="number"
                        errors={errors.cost}
                        isRequired={true}
                        input={{ ...register("cost", { required: true, valueAsNumber: true }) }}
                        label={t("app.connection.cost")}
                    />


                    <Controller
                        name="price_type"
                        rules={{ required: true }}
                        control={control}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <InputSelect
                                label={t("app.connection.price_type")}
                                name={name}
                                options={[
                                    { value: 'fixed', label: t("Fixed Price") },
                                    { value: 'percentage', label: t("Percentage") }
                                ]}
                                value={value}
                                isRequired={true}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                errors={error}
                            />
                        )}
                    />


                    <InputCheckbox
                        label={t("app.connection.is_external")}
                        description={t("app.connection.is_external_description")}
                        id={"is_external"}
                        input={{ ...register("is_external", { required: false }) }}
                    />

                    <InputCheckbox
                        label={t("app.connection.is_quotation_line")}
                        description={t("app.connection.is_quotation_line_description")}
                        id={"is_quotation_line"}
                        input={{ ...register("is_quotation_line", { required: false }) }}
                    />



                </div>
            </div>

            {/* Warning Message */}
            {exception && <Warning message={exception} />}

            {/* Submit Button */}
            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.catalog.add")}
                />
            </div>
        </form>
    );
};


const EditForm = ({ data, exception, onSubmitData, isEditLoading }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (data !== null) {
            for (const key in data) {
                if (typeof data[key] !== "object") {
                    setValue(key, data[key]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (formData) => {
        onSubmitData(formData);
    };

    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4 pt-4 pb-5">

                <InputText
                    id="condition_name"
                    type="text"
                    errors={errors.condition_name}
                    input={{ ...register("condition_name", { required: true }) }}
                    label={t("app.connection.condition_name")}
                />


                <InputText
                    id="condition_type"
                    type="text"
                    errors={errors.condition_type}
                    input={{ ...register("condition_type", { required: true }) }}
                    label={t("app.connection.condition_type")}
                />


                <InputText
                    id="cost"
                    type="number"
                    errors={errors.cost}
                    input={{ ...register("cost", { required: true, valueAsNumber: true }) }}
                    label={t("app.connection.cost")}
                />


                <Controller
                    name="price_type"
                    rules={{ required: true }}
                    control={control}
                    render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                    }) => (
                        <InputSelect
                            label={t("app.connection.price_type")}
                            name={name}
                            options={[
                                { value: 'fixed', label: t("Fixed Price") },
                                { value: 'percentage', label: t("Percentage") }
                            ]}
                            value={value}
                            isRequired={true}
                            onChange={(selectedOption) => {
                                onChange(selectedOption); // Ensure the selected value is updated properly
                            }}
                            errors={error}
                        />
                    )}
                />
                <InputCheckbox
                    label={t("app.connection.is_external")}
                    description={t("app.connection.is_external_description")}
                    id={"is_external"}
                    input={{ ...register("is_external", { required: false }) }}
                />

                <InputCheckbox
                    label={t("app.connection.is_quotation_line")}
                    description={t("app.connection.is_quotation_line_description")}
                    id={"is_quotation_line"}
                    input={{ ...register("is_quotation_line", { required: false }) }}
                />

            </div>

            {/* Warning Message */}
            {exception && <Warning message={exception} />}

            {/* Submit Button */}
            <div className="mt-3">
                <InputSubmit
                    isLoading={isEditLoading}
                    isFullWidth={true}
                    label={t("app.form.save")}
                />
            </div>
        </form>
    );
};
