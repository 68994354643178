import {USER_CONNECTION_VERIFY, VERIFY, CONNECTION, CONNECTIONS, COMPANY, CONDITIONS} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// Connection
export const getConnections = ({params, signal}) =>
    API.get(getPathParam([CONNECTIONS] ) + (params ? serialize(params)  : ''), {signal: signal});

export const getConnection = ({company_id, signal}) =>
    API.get(getPathParam([COMPANY, company_id, CONNECTION ]), {signal: signal});

export const putUserConnectionVerify = ({connection_code}) =>
    API.put(getPathParam([CONNECTION, VERIFY, connection_code]), {});

export const getUserConnectionVerify = ({connection_code}) =>
    API.get(getPathParam([USER_CONNECTION_VERIFY, connection_code ]));

export const getCustomerConditions= ({company_id, connection_id, params}) =>
    API.get(getPathParam([COMPANY, company_id, CONNECTION, connection_id, CONDITIONS] ) + (params ? serialize(params)  : ''));

