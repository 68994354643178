import React, { Fragment, useEffect, useState } from "react";

import InputText from "../../../components/common/form/input-text";
import InputSubmit from "../../../components/common/form/input-submit";
import InputSelect from "../../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../../components/common/alert/banner";
import { useNavigate } from "react-router-dom";
import {
    fetchCountries,
    fetchCurrencies
} from "../../../reducers/configReducer";
import SlidePanel from "../../../components/layout/slide-panel";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import InputTextarea from "../../../components/common/form/input-textarea";
import { postElement, putElement } from "../../../api/config";
import InputDate from "./input-date";
import InputEditor from "./input-editor";
import InputRadioSimple from "./input-radio-simple";
import { getSelectParam } from "../../../utils/converter";
import InputSelectColors from "./input-select-colors";
import { AcquisitionList } from "../alert/acquisition-list";

export default function DynamicForm({ isOpen, setIsOpen, fields, endpoint, isCreate = false, title, navigateLink = false, onResponse = false, refId = false }) {
    const { loggedCompany } = useSelector((state) => state.user);
    const { isCountriesLoading, countries, isCurrenciesListLoading, currenciesList, isLanguagesLoading, languages } = useSelector((state) => state.config);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchCurrencies());
    }, [dispatch, loggedCompany]);



    useEffect(() => {

        if (!isCreate) {
            // eslint-disable-next-line
            fields.map(field => {
                if ('object' in field) {
                    if (field.field === 'importer_currency') {
                        setValue(field.field, getSelectParam(field.object, "code"));
                    } else {
                        setValue(field.field, field.object ? getSelectParam(field.object, "name") : null);
                    }

                } else {
                    setValue(field.field, field.value);
                }
            });
        }
        // eslint-disable-next-line
    }, [fields, setValue]);


    const sendData = async (data) => {
        if (loggedCompany) {

            if (refId) {
                data['id'] = refId;
            }

            setLoading(true);
            (!isCreate ? putElement(endpoint, data) : postElement(endpoint, data)).then((response) => {
                setLoading(false);
                if (navigateLink) {
                    navigate(navigateLink);
                }

                if (onResponse) {
                    onResponse(response);
                    setIsOpen(false);
                }
            }).catch((e) => {
                setException(e.message);
                setLoading(false);
            });
        }
    };


    const onSubmit = async (data) => {
        await sendData(data);
    };


    return (
        <SlidePanel
            title={title}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            bg={'bg-white'}
            size='max-w-2xl'
            setClean={() => { }}
        >
            <form onSubmit={handleSubmit(onSubmit)} className={' border-none pb-5 mt-5'}>
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">



                    {
                        fields.map(((field, index) => (
                            <Fragment key={index}>

                                {
                                    field.formType === 'string' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <InputText
                                                id={field.field}
                                                type={"text"}
                                                errors={errors[field.field]}
                                                input={{ ...register(field.field, { required: field.required }) }}
                                                label={t(field.translation)}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    field.formType === 'textarea' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <div className={'col-span-2'}>
                                                <InputTextarea
                                                    id={field.field}
                                                    errors={errors[field.field]}
                                                    input={{ ...register(field.field, { required: field.required }) }}
                                                    label={t(field.translation)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    field.formType === 'date' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState: { error }, }) => (
                                                    <InputDate
                                                        label={t(field.translation)}
                                                        format={"YYYY-MM-DD HH:mm:ss"}
                                                        placeholder={"Es. 01/12/2021"}
                                                        activeMinDate={false}
                                                        startDate={value}
                                                        disabled={false}
                                                        isShowTime={field?.config?.isActiveTime ?? false}
                                                        errors={error}
                                                        name={name}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }


                                {
                                    field.formType === 'editor' && (
                                        <div className="col-span-4">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({ field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputEditor
                                                        label={t(field.translation)}
                                                        errors={error}
                                                        defaultValue={value}
                                                        name={name}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    field.formType === 'radio-btn' && (
                                        <div className="col-span-4">
                                            <InputRadioSimple
                                                name={field.group}
                                                errors={errors.default_type}
                                                input={{ ...register(field.group, { required: field.required }) }}
                                                label={t(field.translation)}
                                                description={t(field.description)}
                                                value={field.field}
                                            />
                                        </div>

                                    )
                                }


                                {
                                    field.formType === 'number' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputFormattedNumber
                                                        label={t(field.translation)}
                                                        name={name}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        prefix={field?.config?.prefix ?? ''}
                                                        suffix={field?.config?.suffix ?? ''}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>

                                    )
                                }

                                {
                                    field.formType === 'currency' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputFormattedNumber
                                                        label={t(field.translation)}
                                                        name={name}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        prefix={"€ "}
                                                        suffix={""}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    field.formType === 'country' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputSelect
                                                        label={t(field.translation)}
                                                        name={name}
                                                        options={countries}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        isLoading={isCountriesLoading}
                                                        isDisabled={isCountriesLoading}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    field.formType === 'select' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputSelect
                                                        label={t(field.translation)}
                                                        name={name}
                                                        options={field.options} // Pass dynamic options here
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        isLoading={field.isLoading ?? false}
                                                        isDisabled={field.isDisabled ?? false}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    field.formType === 'languages' && (
                                        <div className="col-span-4">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputSelect
                                                        label={t(field.translation)}
                                                        name={name}
                                                        options={languages}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        isLoading={isLanguagesLoading}
                                                        isDisabled={isLanguagesLoading}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    field.formType === 'currencies' && (
                                        <div className="col-span-4">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputSelect
                                                        label={t(field.translation)}
                                                        name={name}
                                                        options={currenciesList}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        isLoading={isCurrenciesListLoading}
                                                        isDisabled={isCurrenciesListLoading}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }



                                {
                                    field.formType === 'colors' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={field.field}
                                                rules={{ required: field.required }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (

                                                    <InputSelectColors
                                                        label={t(field.translation)}
                                                        name={name}
                                                        translationPattern={'filter.general.'}
                                                        onChangeData={(e) => {
                                                            onChange(e);
                                                        }}
                                                        valueData={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }


                                {
                                    field.formType === 'priority' && (
                                        <div className="col-span-4 md:col-span-2">
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700">
                                                    {t("app.leads.priority")}
                                                </label>
                                                <AcquisitionList
                                                    status={field?.value ?? 'low'}
                                                    onChangeStatus={(r) => {
                                                        setValue(field.field, r);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }







                            </Fragment>
                        )))
                    }

                </div>


                {/* Warning */}
                {exception && <Warning message={exception} />}

                <div className="flex before:flex-1 items-center justify-between mt-6">

                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.form.save")}
                    />
                </div>

            </form>

        </SlidePanel>
    );
}
