import React, { useEffect, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormPopup from "../../../components/common/popup/form-popup";
import InputSubmit from "../../../components/common/form/input-submit";
import { Warning } from "../../../components/common/alert/banner";
import InputSelect from "../../../components/common/form/input-select";
import { useTranslation } from "react-i18next";
import {
    deleteQuotationExtraCost,
    getQuotationExtraCosts,
    postQuotationExtraCost
} from "../../../api/quotation";
import { getCustomerConditions } from '../../../api/connection';

export default function ManageExtraCosts({ isOpen, setIsOpen, quotationDetail, extraCost, onSetExtraCost }) {

    const { loggedCompany } = useSelector((state) => state.user);
    const { handleSubmit, control } = useForm();
    let { id } = useParams();

    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [isConditionLoading, setConditionLoading] = useState(false);
    const [exception, setException] = useState(false);

    const [conditions, setConditions] = useState([]);
    const [extraCosts, setExtraCosts] = useState([]);

    const fetchExtraCosts = async () => {
        if (loggedCompany && quotationDetail !== null) {
            try {
                const res = await getQuotationExtraCosts({
                    quotation_id: quotationDetail.id,
                    params: null,
                });
                setExtraCosts(res.extra_costs);
            } catch (e) {
                setExtraCosts([]);
            }
        }
    };

    const storeCondition = async (data) => {
        if (loggedCompany) {
            setLoading(true);
            postQuotationExtraCost({ quotation_id: id, data })
                .then(response => {
                    onSetExtraCost(response);
                    fetchExtraCosts();
                    setLoading(false);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };

    const removeExtraCost = async (data) => {
        if (loggedCompany) {
            setLoading(true);
            deleteQuotationExtraCost({ quotation_id: id, id: data })
                .then(response => {
                    onSetExtraCost(response);
                    fetchExtraCosts();
                    setLoading(false);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchCondition() {
            setConditionLoading(true);
            if (loggedCompany && quotationDetail !== null) {
                try {
                    const response = await getCustomerConditions({
                        company_id: loggedCompany.id,
                        connection_id: quotationDetail.customer.id,
                        params: null,
                        signal,
                    });

                    if (Array.isArray(response.conditions)) {
                        setConditions(response.conditions);
                    } else {
                        setConditions([]);
                    }

                } catch (e) {
                    setConditions([]);
                } finally {
                    setConditionLoading(false);
                }
            }
        }

        fetchCondition();

        return () => {
            controller.abort();
        };
    }, [loggedCompany, quotationDetail]);

    useEffect(() => {
        fetchExtraCosts();
        // eslint-disable-next-line
    }, [loggedCompany, quotationDetail]);

    const onSubmit = async (data) => {
        await storeCondition(data);
    };

    return (
        <FormPopup title={t("app.quotations.manage_costs")} isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-2 gap-x-2 sm:grid-cols-1">
                        <Controller
                            name="condition"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputSelect
                                    label={t("app.leads.condition")}
                                    name={name}
                                    options={conditions.map(c => ({
                                        id: c.id,
                                        label: c.condition_name ?? `${c.condition_type} ${c.cost} ${c.price_type}`
                                    }))}
                                    value={value}
                                    onChange={onChange}
                                    isLoading={isConditionLoading}
                                    isDisabled={isConditionLoading}
                                    errors={error}
                                />
                            )}
                        />
                        <div className="flex before:flex-1 items-center justify-between mt-6">
                            <InputSubmit
                                isLoading={isLoading}
                                label={t("app.quotations.add_extra_cost")}
                            />
                        </div>
                    </div>
                </div>

                {exception && (
                    <Warning message={exception} />
                )}
            </form>

            {extraCosts && extraCosts.length > 0 && (
                <div className="mt-6">
                    <h3 className="font-semibold text-lg">{t('app.quotation.selected_conditions')}</h3>
                    <table className="w-full mt-2 border-collapse border border-gray-300">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="border p-2">Name</th>
                            <th className="border p-2">Price</th>
                            <th className="border p-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {extraCosts.map((extra) => (
                            <tr key={extra.id}>
                                <td className="border p-2">{extra.condition.condition_name || 'N/A'}</td>
                                <td className="border p-2">{extra.price}</td>
                                <td className="border p-2">
                                    <button
                                        type="button"
                                        className="text-left text-red-500"
                                        onClick={() => removeExtraCost(extra.id)}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </FormPopup>
    );
}
